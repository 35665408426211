import { useAppSelector } from "@modules/store/redux"
import { useRouter } from "next/router"
import { loginPagePath, postLoginUrl } from "@routing/paths"
import { isRoleMatching } from "../roles"
import { UserRole } from "../types"

export const useAuthGuard = ({
  role,
  public: publicPage,
}: {
  role?: UserRole
  public?: boolean
}) => {
  const router = useRouter()
  const { authenticated, userRole, initialized } = useAppSelector(
    (state) => state.auth
  )

  if (publicPage) {
    return
  }

  if (!initialized) {
    return
  }

  if (!authenticated) {
    router.push(loginPagePath(router.asPath))
    return
  }

  if (!role) {
    return
  }

  if (!userRole || !isRoleMatching(role, userRole, false)) {
    router.push(postLoginUrl())
  }
}
