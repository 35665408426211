import { useAuthGuard } from "@modules/auth/hooks"
import { UserRole } from "@modules/auth/types"
import React from "react"

interface Props {
  children: any
  role?: UserRole
  publicPage?: boolean
}

export const WrapPage = ({ children, role, publicPage }: Props) => {
  useAuthGuard({ role, public: publicPage })

  return <>{children}</>
}
