import { getPostLoginUrl } from "@modules/auth/utils/redirect"
import { useAppSelector } from "@modules/store/redux"
import { useRouter } from "next/router"
import { useEffect } from "react"

export const usePostLoginRedirect = () => {
  const router = useRouter()
  const { initialized, authenticated } = useAppSelector((state) => state.auth)
  useEffect(() => {
    if (!initialized || !authenticated) {
      return
    }

    router.push(getPostLoginUrl())
  }, [initialized, authenticated])
}
