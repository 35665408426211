import { UserRole } from "../types"

const rolesMap: {
  [key: string]: UserRole[]
} = {
  ["admin"]: ["admin"],
  ["backoffice"]: ["admin", "backoffice"],
  ["seller"]: ["admin", "backoffice", "seller"],
  ["customer"]: ["admin", "customer"],
}

const expandUserRoles = (userType: UserRole) => rolesMap[userType]

export const isRoleMatching = (
  role: UserRole,
  userType: UserRole,
  strict: boolean
) => {
  if (strict) {
    return userType === role || userType === "admin"
  }
  const userTypes = expandUserRoles(role)
  return userType && userTypes.includes(userType)
}
