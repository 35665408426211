import { PageSEO } from "@modules/seo"
import { WrapPage } from "@root/index"
import { wrapPage } from "@root/page"
import { usePostLoginRedirect } from "@routing/guard"
import type { NextPage } from "next"

const Home: NextPage = () => {
  usePostLoginRedirect()
  return (
    <WrapPage>
      <PageSEO pageTranslationsKey="common" siteTranslationsKey="common" />
    </WrapPage>
  )
}

export default Home

export async function getStaticProps({ locale }: any) {
  return await wrapPage({
    locale,
  })
}
